import * as React from 'react'
import styled from 'styled-components'

import { t, tc } from '@owl-nest/localize'
import * as entities from '@ulule/entities'
import * as shadow from '@owl-nest/shadow'
import * as plume from '@ulule/owl-kit-components/next'

import { Carousel } from '../components/Carousel'
import * as style from '../style'

type PartnersProps = {
  partners: entities.homepage.OngoingPartnersOperation[]
}

export function Partners({ partners }: PartnersProps): React.ReactElement {
  if (partners.length === 0) {
    return <></>
  }

  return (
    <style.WideSection>
      <style.TitleGroup>
        <style.SectionTitle>
          {tc('[highlight: Partners] for your creative projects', {
            highlight: <plume.HighlightedTitle color="blue" />,
          })}
        </style.SectionTitle>
      </style.TitleGroup>
      <Carousel>
        <CardsWrapper>
          {partners.map((partnerOperation, index) => {
            return <PartnerOperationCardWithTracking key={index} partnerOperation={partnerOperation} />
          })}
        </CardsWrapper>
      </Carousel>
      <style.CenteredCTA href="/pages/appel-a-projet/">{t('See all calls for proposals')}</style.CenteredCTA>
    </style.WideSection>
  )
}

function PartnerOperationCardWithTracking({
  partnerOperation,
}: {
  partnerOperation: entities.homepage.OngoingPartnersOperation
}): React.ReactElement {
  const partnerOperationRef = React.useRef(null)

  shadow.usePartnershipTracker(partnerOperation.partnerUserId ?? undefined, partnerOperationRef, {
    click: {
      enabled: false,
    },
    eventPosition: `homepage-partner-${partnerOperation.partnerUserId}`,
    ignoreLocationChange: true,
    impression: {
      enabled: true,
      once: true,
    },
  })

  return (
    <li ref={partnerOperationRef}>
      <plume.PartnerOperationCard
        compact
        isOngoing
        buttonUrl={partnerOperation.urlMore}
        buttonTarget={!partnerOperation.urlMore.includes('ulule.com') ? '_blank' : undefined}
        linkUrl={partnerOperation.urlProjectList ?? undefined}
        linkTarget={!partnerOperation.urlProjectList?.includes('ulule.com') ? '_blank' : undefined}
        translation={{
          status: t('Ongoing'),
          description: partnerOperation.shortDescription,
          buttonMore: t('Apply'),
          link: t('View selected projects'),
        }}
        pictureUrl={partnerOperation.cover.src}
        pictureSrcSet={partnerOperation.cover.srcSet}
      />
    </li>
  )
}

const CardsWrapper = styled.ul`
  list-style: none;
  padding: 0 16px;
  white-space: nowrap;

  ${plume.PartnerOperationCard} {
    display: inline-flex;
    height: 100%;
    margin-right: 8px;
    white-space: pre-wrap;
    width: 245px;

    &:last-of-type {
      margin-right: 0;
    }
  }

  @media screen and ${plume.BREAKPOINTS.TABLET} {
    ${plume.PartnerOperationCard} {
      flex-basis: 245px;
      flex-grow: 0;
      flex-shrink: 0;
      margin-right: 0;
      width: auto;
    }

    display: grid;
    gap: 20px;
    grid-template-columns: repeat(6, 320px);
    grid-template-rows: auto auto;
    padding: 0 30px;
  }

  @media screen and (min-width: 2050px) {
    padding: 0;
    margin: 0 auto;
  }
`
